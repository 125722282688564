.ui-notification {
  width: 400px !important;
  max-width: 95%;
  border-radius: 10px !important;
  .error-title {
    color: white;
    text-align: left;
  }
  .error-message {
    padding: 40px;
    border-radius: 10px;
    text-align: center;
    .body-m();
    .semibold();
  }
}

.info {
  padding: @spacing-s @spacing;
  background: @blue;
  border-radius: 5px;
  color: white;
  .body-s();
  .body-strong();

  &.info-discreet {
    padding: 0;
    background: none;
    color: black; // @TODO Refacto color
  }

  .link {
    color: white;
  }
}

.form-errors {
  .info();
  .center();

  padding: 0;
  margin: @spacing 0 0;
  background: none;
  color: @orange;
  list-style-type: none;
}

.cookies-disclaimer {
  .info();

  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  border-radius: 0;
  box-shadow: 0 -2px 5px fade(black, 25%);

  .disclaimer-footer {
    .center();

    padding-top: @spacing;
  }
}
