.table-container {
  overflow-x: auto;
}

.table-1 {
  .body-s();

  border-collapse: collapse;
  color: @dark-grey;
  overflow-x: auto;

  thead {
    background: @blue;
    color: white;

    th {
      padding: @spacing-xs @spacing;
    }
  }

  tbody {
    background: white;

    tr:nth-child(2n) {
      background: fade(@blue, 7%);

      [class^="select"] {
        border: 1px solid white;
        background-color: fade(white, 20%);
      }
    }
  }

  td {
    padding: @spacing-xs @spacing-xs;
    text-align: center;
  }

  & th:not(.bold) {
    font-weight: normal;
  }

  .cell-actions .link {
    margin: 0 @spacing-xs;
  }
}

.th-1 {
  background-color: @green;
}

.th-2 {
  background-color: @orange;
}

.th-3 {
  background-color: @dark-grey;
  color: white;
}
td.sep-r {
  border-right: 1px solid @blue;
}
.td-1 {
  color: @green;
}
.td-1,
.th-1 {
  &.sep-l {
    border-left: 1px solid @green;
  }
  &.sep-r {
    border-right: 1px solid @green;
  }
}
thead > tr:not(:first-child) .th-1 {
  background-color: mix(@green, white, 40%);
}
.td-2 {
  color: @orange;
}
.td-2,
.th-2 {
  &.sep-l {
    border-left: 1px solid @orange;
  }
  &.sep-r {
    border-right: 1px solid @orange;
  }
}
thead > tr:not(:first-child) .th-2 {
  background-color: mix(@orange, white, 40%);
}

thead > tr:not(:first-child) .th-3 {
  background-color: lighten(@dark-grey, 5%);
}
