.card {
  display: block;
  background: white;
  border-radius: 5px;
  transition: box-shadow 0.2s;

  p {
    margin: 0;
  }
  .card-head {
    display: flex;
    height: 5rem;
    flex-direction: column;
    justify-content: center;
    padding: @spacing;
    background: @blue;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: white;
    transition: background-color 0.2s;

    ::selection {
      background-color: white;
      color: @blue;
    }
  }
  .card-title {
    .heading-s();

    text-align: center;
  }
  .card-footer {
    padding: @spacing-l @spacing;
    text-align: center;
  }
  .card-body {
    padding: @spacing;

    & + .card-footer {
      margin-top: -@spacing-xl;
    }
  }
  .status {
    margin-bottom: @spacing;
  }
  .description {
    /* FOR CSS FRAMEWORK : CLAMPING BY FADE */
    @lh: 1.2em;

    position: relative;
    overflow: hidden;
    height: 3 * @lh;
    margin-bottom: @spacing-xs;
    color: @dark-grey;
    line-height: @lh;
    overflow-wrap: break-word;
    &,
    & > p {
      .body-s();

      line-height: @lh;
    }
    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 70%;
      height: @lh;
      background: linear-gradient(
        45deg,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 100%
      );
      content: "";
      text-align: right;
    }
  }
  .date,
  .user {
    display: flex;
    align-items: center;
    .body-xs();
    .picto {
      height: 35px;
    }
    &,
    & > * {
      .nowrap();
    }
  }
  .score {
    text-align: center;
  }
  .card-footer-img {
    position: relative;
    overflow: hidden;
    height: 100px;
    background: no-repeat center center;
    background-size: cover;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: @blue;
      content: "";
      opacity: 0.2;
    }

    &.default-img {
      background: fade(@blue, 20%);
      &::after {
        background: url(../img/logo-white.png) no-repeat center center;
        background-size: contain;
        opacity: 0.5;
      }
    }
  }
}

a:hover > .card {
  box-shadow: 0 10px 10px -6px rgba(0, 0, 0, 0.2);
  .card-head {
    background-color: @green;
  }
}
