._btn() {
  display: inline-block;
  padding: 0.75rem 2rem;
  border: 1px solid transparent;
  background-color: @light-grey;
  color: @dark-grey;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: color @std-transition-duration @std-transition-ease,
    background-color @std-transition-duration @std-transition-ease,
    background-position @std-transition-duration @std-transition-ease,
    border-color @std-transition-duration @std-transition-ease,
    box-shadow @std-transition-duration @std-transition-ease;
  vertical-align: middle;
  .body-s();
  .body-strong();

  &:not(:disabled):not(.disabled):not([disabled]):hover {
    background-color: @grey;
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    box-shadow: 0 0 0 2px fade(@blue, 50%);
    outline: none;
  }

  &:disabled,
  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  &:not(.block) + &:not(.block) {
    margin-left: 0.7em;
  }

  & + &.block,
  &.block ~ & {
    margin-top: 0.5rem;
  }

  &.btn-small {
    .body-xs();

    padding: 0.3rem 0.6rem;
  }

  &.btn-block {
    display: block;
    width: 100%;
  }

  &.btn-full-height {
    height: 100%;
  }

  .icon {
    width: @spacing-l;

    & + *:not(div) {
      margin-left: 10px;
    }
  }
}

.btn-1 {
  ._btn();
  .bold();

  background-color: @blue;
  border-radius: 5px;
  color: white;

  &:disabled,
  &.disabled,
  &[disabled] {
    opacity: 0.3;
  }

  &:not(:disabled):not(.disabled):not([disabled]):hover {
    background-color: @dark-grey;
  }
}

.btn-2 {
  .btn-1();

  background-color: @grey;
}

.btn-outlined {
  ._btn();

  border: 1px solid @grey;
  border-radius: 5px;
  color: @dark-grey;

  &:disabled,
  &.disabled {
    opacity: 0.3;
  }

  &:not(:disabled):not(.disabled):hover {
    border-color: @dark-grey;
    background: none;
  }
}

.btn-icon {
  ._btn();

  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  margin: 0;
  background: none;
  transition: transform 0.2s;

  &:not(:disabled):not(.disabled):not([disabled]):hover:not([disabled]):hover {
    background: none;
    transform: scale(1.2);
  }

  img {
    width: 100%;
    max-height: 100%;
  }

  &.btn-icon-m {
    width: 1.2rem;
    height: 1.2rem;
  }
}

._link() {
  display: inline-block;
  padding: 0;
  border: none;
  background: none;
  color: @blue;
  cursor: pointer;
  text-decoration: underline;
  transition: color @std-transition-duration @std-transition-ease;
  .body();
  .body-strong();

  &:not(:disabled):not(.disabled):hover {
    color: @dark-grey;
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    color: @dark-grey;
    outline: none;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.link {
  ._link();

  &.link-small {
    .body-s();
  }
}

.ghost-link {
  color: inherit;
  text-decoration: none;
}

.discreet-link {
  color: @grey;
  line-height: 1;
  text-decoration: none;
}

.btns-bar {
  .center();

  display: flex;
  align-items: center;
  justify-content: center;

  [class*="btn-"]:not(.btn-icon) {
    margin-right: @spacing-xxs;
    margin-left: @spacing-xxs;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  .btn-icon + .btn-icon {
    margin-left: @spacing-xxs;
  }

  &.vertical {
    display: block;
    [class*="btn-"]:not(.btn-icon) {
      display: block;
      max-width: 30rem;
      margin: @spacing-xs auto;
    }
  }
}

.btn-checkbox {
  ._btn();

  width: 0.825rem;
  height: 0.85rem;
  padding: 1.65px;
  border: 2px solid lighten(@dark-grey, 30%);
  margin: 0;
  background-color: white;
  border-radius: 2px;
  color: @dark-grey;
  font-family: monospace;
  font-size: 0.65rem;
  font-weight: bold;
  line-height: 0.425rem;

  &.checked {
    border: none;
    background-color: @orange;
    color: white;

    &:not(:disabled):not(.disabled):not([disabled]):hover {
      background-color: darken(@orange, 10%);
    }
  }

  &:focus,
  &.focus,
  &:active,
  &.active {
    box-shadow: none;
  }

  &:not(.checked):not(:disabled):not(.disabled):not([disabled]):hover {
    border: 2px solid lighten(@dark-grey, 10%);
    background-color: white;
  }

  img {
    width: 100%;
    max-height: 100%;
  }
}
