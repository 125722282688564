/* --- PARAMS --- */
@input-v-padding: 1em;
@input-h-padding: 1em;

.inputs-borders {
  border: 1px solid @light-grey;
  border-radius: 5px;
}

.inputs-font-style {
  .body-s();
}

@input-text-color: black;
@input-background-color: white;
@placeholder-text-color: @grey;
@input-focus-color: black;
@input-focus-bg-color: white;
@input-focus-border-color: @blue;
@input-disabled-bg-color: @light-grey;

/* --- */

fieldset {
  padding: 0;
  border: none;
  margin: 0;
}

.input {
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: @input-v-padding @input-h-padding;
  background-color: @input-background-color;
  caret-color: @input-text-color;
  color: @input-text-color;
  line-height: 1em;
  .inputs-borders();
  .inputs-font-style();

  &.input-outlined {
    padding: 5px 10px;
    border: 1px solid @light-grey;
    background: fade(white, 30%);
    border-radius: 2px;
  }

  &.input-s {
    display: inline-block;
    width: 5em;
    padding: @spacing-xxs @spacing-xs;
    margin: 0 @spacing-xxs;
  }

  &:focus {
    border-color: @input-focus-border-color;
    background-color: @input-focus-bg-color;
    caret-color: @input-focus-color;
    color: @input-focus-color;
    outline: 0;
  }

  &:disabled,
  &[readonly],
  &.disabled {
    background-color: @input-disabled-bg-color;
    cursor: not-allowed;
    opacity: 1;
  }

  &::placeholder {
    color: @placeholder-text-color;
    opacity: 1;
  }

  /* -- BROWSERS' SPECIFICITIES -- */

  /* IE10+ select's carret style */
  &::-ms-expand {
    border: 0;
    background-color: transparent;
  }

  /* FF select outline */
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 @input-text-color;
  }

  /* -- LAYOUTING -- */
  & + & {
    margin-top: @spacing;
  }

  &.computed-result {
    padding-right: 0;
    padding-left: 0;
    border: none;
    background: transparent;
    .heading-s();
  }

  &.input-search {
    // for sx-ui >> input aligned with a button
    padding-top: 0.6rem;
    padding-bottom: 0.6rem;
    padding-left: 40px;
    background: url(../img/picto-search.svg) left center no-repeat, white;
    background-position: 10px 10px;
    background-size: 20px 20px, cover;
  }
}

/* TODO: params */
.input-label {
  display: inline-block;
  margin: 0;
  color: @dark-grey;
  .body-s();
  .body-strong();

  &.label-inline {
    display: inline;
  }
}

.input-tip {
  .body-xs();

  margin-top: @spacing-xxs;
  margin-bottom: 0;
  color: @grey;
}

.input-block {
  & + &,
  & + .btn {
    margin-top: 1.5em;
  }
  &.condensed {
    & + & {
      margin-top: 1em;
    }
  }

  .input-btn {
    margin-top: 10px;
    text-align: right;
  }
}
.form-error {
  .body-xs();

  color: @red;
}

.select {
  .input();

  padding-top: 1.2em;
  padding-right: 2.2em;
  padding-bottom: 1.2em;
  appearance: none;
  background-image: url("../img/picto-triangle-down.svg");
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  background-size: 1em;
  cursor: pointer;
  .nowrap();

  &.small {
    padding-top: 0.3em;
    padding-right: 1.5em;
    padding-bottom: 0.3em;
    background-position: right 5px top 50%;
  }
}

.select-s {
  .select();

  padding-top: 0.8em;
  padding-bottom: 0.8em;
}

.textarea {
  .input();

  height: 8rem;
  resize: vertical;
}

.form-footer {
  margin-top: @spacing-xl;
}
.form-errors + .form-footer {
  margin-top: @spacing;
}

.lang-switch {
  display: flex;
  align-items: center;
  .select {
    display: inline-block;
    width: auto;
    padding-left: 0.5em;
    border: none;
    background-color: transparent;
    background-image: url("../img/picto-triangle-down-black.svg");
  }
  &::before {
    display: inline-block;
    width: 1em;
    height: 1em;
    background: url(../img/picto-globe.svg) center center no-repeat;
    background-size: contain;
    content: "";
  }
}
.header .lang-switch {
  margin-left: @spacing-xs;
}

/* inputs stacking/spacing rules */

/* stylelint-disable */
.input-block {
  margin-top: @spacing;

  .col {
    padding-top: 0;
  }
}

/* NOTE CG : préférer notion de 'bloc input' pour le ui-kit final */
.input + .input-label,
.select + .input-label,
.form-radio + .input-label,
.input-with-addon + .input-label,
.input-with-addon + .input-with-addon,
.input-tip + .input-label,
.textarea + .input-label,
.select + .input,
.select + .textarea,
.select + .select,
.input-block + .input-label {
  margin-top: @spacing;
}

.input + .input,
.input-label + .input,
.input-label + .textarea,
.textarea-with-addon,
.input-with-addon,
.input-radio-label {
  margin-top: @spacing-xxs;
}

[disabled] {
  .input,
  .textarea,
  .select {
    border-color: darken(@x-light-grey, 5%);
    background: @x-light-grey;
    color: @dark-grey;
  }
}
/* stylelint-enable */
