@keyframes fade-in {
  0% {
    opacity: 0;
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
.fade-in() {
  animation: fade-in 1s ease;
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale3d(0, 0, 0);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1);
  }
}
.pop-in() {
  animation: pop-in 1.5s ease;
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.slide-up() {
  animation: slide-up 1s ease;
}

@keyframes slide-down {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.slide-down() {
  animation: slide-down 1s ease;
}
.slide-down-fast() {
  animation: slide-down 0.5s ease;
}

@keyframes slide-left {
  0% {
    opacity: 0;
    transform: translateX(30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.slide-left() {
  animation: slide-left 1s ease;
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-30px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
.slide-right() {
  animation: slide-right 1s ease;
}

@keyframes sidebar-slide {
  0% {
    left: -100%;
    opacity: 0;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}
.sidebar-slide() {
  animation: sidebar-slide 0.3s ease;
}

@keyframes flip-v {
  0% {
    opacity: 0;
    transform: rotateX(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotateX(0);
  }
}
.flip-v() {
  animation: flip-v 1.5s ease;
}

@keyframes flip-h {
  0% {
    opacity: 0;
    transform: rotateY(-90deg);
  }
  100% {
    opacity: 1;
    transform: rotateY(0);
  }
}
.flip-h() {
  animation: flip-h 1.5s ease;
}

@keyframes bg-breathe {
  0% {
    background-size: 130%;
  }
  50% {
    background-size: 150%;
  }
  100% {
    background-size: 130%;
  }
}

.bg-breathe() {
  animation: bg-breathe 10s linear infinite;
}

@keyframes appear-3d {
  0% {
    opacity: 0;
    transform: translate3d(0, -100%, 0) rotate3d(1, 1, 1, 40deg)
      scale3d(0.5, 0.5, 0.5);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) rotate3d(0, 0, 0, 0deg) scale3d(1, 1, 1);
  }
}
.appear-3d() {
  animation: appear-3d 1s ease;
}
