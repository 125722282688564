.plot {
  position: relative;
  height: 100%;
}

.plot-tooltip {
  .body-s();

  position: absolute;
  display: none;
  padding: 10px;
  background-color: @xx-light-grey;
  border-radius: 5px;
  box-shadow: 5px 5px 5px 5px fade(black, 10%);
  pointer-events: none;

  strong {
    display: block;
  }
}

.plot-draw {
  display: block;
  max-width: 1000px;
  max-height: 100%;
  margin: 0 auto;
}

.plot-circle {
  cursor: pointer;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2));
  transition: filter 0.2s;

  &:hover {
    filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.3));
  }
}

.tick {
  .body-s();

  color: @dark-grey;
}

.axis-label {
  .body();
  .semibold();

  color: @dark-grey;
}
