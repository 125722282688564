@dark-grey: #444444;
@grey: #b5babe;
@light-grey: #f4f5f5;
@x-light-grey: #eeeeee;
@xx-light-grey: #f8f9f9;
@blue: #219dd8;
@red: #dc2419;
@green: #02bd71;
@orange: #f8a700;

.bg-xx-light-grey {
  background-color: @xx-light-grey;
}

/* SEMANTIC COLORS */
@color-customer-score: @blue;
@color-company-score: @green;
