@auth-form-max-width: 500px;

.auth-footer {
  .body-s();

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: @spacing @spacing-s;
  border-top: 1px solid @x-light-grey;
  background: white;

  .link {
    .body-s();

    margin: 0 @spacing-xs;
  }

  .copyright {
    .body-xs();

    width: 100%;
    margin-top: @spacing-xs;
    text-align: center;
  }

  @media (max-width: @bp-s-max) {
    flex-direction: column;
  }
}

.auth-layout {
  overflow: hidden;
  background: @light-grey;

  .sidebar {
    position: relative;
    padding: @spacing-l;
    background: url(../img/background.png) center center;
    background-size: cover;

    .lang-switch {
      position: absolute;
      top: @spacing;
      left: @spacing;
    }

    .logo {
      width: 100%;
      max-width: 300px;
      .fade-in();
    }

    .sidebar-nav {
      text-align: center;
    }
    .menu-item {
      .slide-up();
    }
  }

  .sidebar-menu {
    padding: 0;
    margin: @spacing-l 0 0;
    list-style: none;
    text-align: center;

    .menu-item {
      padding: @spacing-xs @spacing;
      .heading();
      .semibold();
      a,
      .link {
        display: inline-block;
        color: white;
        text-decoration: none;
        text-transform: uppercase;
        transition: color 0.2s linear, transform 0.2s linear;
        .body-s();

        &:hover,
        &:active,
        &.active {
          color: white;
          text-decoration: underline;
        }
        &.active {
          .bold();

          text-decoration: underline;
        }
      }
    }
  }

  .page-title {
    .center();

    margin-bottom: @spacing-xl;
    .slide-down();
  }

  .info {
    max-width: @auth-form-max-width;
    margin: 0 auto @spacing auto;
    .center();
  }

  .content {
    .fade-in();
  }

  .page-head {
    padding-top: @spacing-l;
  }

  .page-footer {
    padding-bottom: @spacing-l;
  }
}

.auth-form {
  max-width: @auth-form-max-width;
  margin: 0 auto;
  .slide-up();

  .form-footer {
    .center();

    [type="submit"] + div,
    [class*="btn-"] + div {
      margin-top: @spacing-xs;
    }
  }
}
