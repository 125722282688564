/* FONTS */
.main-font {
  font-family: "Nunito Sans", Helvetica, Arial, sans-serif;
}

/* WEIGHTS */
.x-light {
  font-weight: 200;
}
.light {
  font-weight: 300;
}
.regular {
  font-weight: 400;
}
.semibold {
  font-weight: 600;
}
.bold {
  font-weight: 700;
}
.x-bold {
  font-weight: 800;
}

/* TYPOGRAPHIC STYLES */
.heading {
  .main-font();
  .bold();

  font-size: 1.4rem;
  line-height: 1.1;
}
.heading-s {
  .main-font();
  .bold();

  font-size: 1.1rem;
  line-height: 1.1;
}
.heading-m {
  .main-font();
  .bold();

  font-size: 1.6rem;
  line-height: 1.1;
}
.body {
  .main-font();
  .regular();

  font-size: 1rem;
  line-height: 1;
}
.body-s {
  .body();

  font-size: 0.9rem;
}
.body-xs {
  .body();

  font-size: 0.8rem;
  line-height: 1.2;
}
.body-m {
  .body();

  font-size: 1.1rem;
}
.body-strong {
  .semibold();
}

/* SEMANTIC STYLES */
.page-title {
  .heading();

  color: @dark-grey;
}
.section-title {
  .heading-s();

  padding-bottom: @spacing;
  color: black;

  &.txt-important {
    color: @orange;
  }
}
.section-subtitle {
  .body-s();
  .bold();

  padding-bottom: @spacing;
  color: @grey;
}
.status {
  .body-s();
  .bold();

  color: @green;
  text-transform: uppercase;
}

.score {
  .heading-s();

  margin-top: @spacing-xs;
  color: @orange;

  .picto {
    height: 1em;
    margin-right: @spacing-xxs;
  }
  .label {
    .body-xs();

    margin: 5px 0;
  }
  &.score-big {
    .heading();
    .label {
      .bold();
    }
  }
  &.score-customer {
    color: @color-customer-score;
  }
  &.score-company {
    color: @color-company-score;
  }
  &.nd {
    color: @grey;
    opacity: 0.5;
    .picto {
      filter: grayscale(100%);
    }
  }
}
.scores {
  padding-top: @spacing-xs;
  border-top: 1px solid @light-grey;
  margin-top: @spacing-s;
  text-align: center;
  .score {
    display: inline-block;
    margin: @spacing-xs;
  }
}

.menu-item {
  text-transform: uppercase;
}

/* DEFAULT TYPOGRAPHIC STYLES */
html,
body {
  padding: 0;
  margin: 0;
  .main-font();
}
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
  .heading();
}
h1 {
  .heading();
}
h2 {
  .heading-m();
}
h3 {
  .heading-s();
}
h4,
h5,
h6 {
  .body-m();
}
p {
  padding: 0;
  margin: 0.5em 0;
  .body();
}
::selection {
  background-color: @blue;
  color: white;
}
