.list {
  .list-item {
    padding: @spacing-xs 0;

    .item-title {
      .body-s();
      .bold();
    }

    .item-body {
      .body-s();

      color: @dark-grey;
    }

    .item-title + .item-body {
      margin-top: @spacing-xxs;
    }

    &:not(:first-child) {
      border-top: 1px solid @grey;
    }
  }
}
