.tooltip {
  position: relative;
  display: inline-block;
  .tooltiptext {
    .body-xs();

    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    width: 200px;
    padding: 5px 0;
    margin-bottom: 5px;
    margin-left: -60px;
    background-color: @dark-grey;
    border-radius: 6px;
    color: #fff;

    /* Fade in tooltip */
    opacity: 0;
    text-align: center;
    transition: opacity 1s;
    visibility: hidden;
  }
}

.tooltip:hover .tooltiptext {
  opacity: 1;
  visibility: visible;
}

.tooltip::before {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 1.8ex;
  height: 1.8ex;
  padding: 3px;
  border: 1px solid @dark-grey;
  margin-right: 4px;
  background: @light-grey;
  border-radius: 3em;
  color: @dark-grey;
  content: "?";
  font-size: 1.4ex;
  font-weight: bold;
  line-height: 1;
  text-align: center;
  text-decoration: none;
}

.tooltip:hover::before {
  border-color: white;
  background: @dark-grey;
  color: white;
  text-decoration: none;
}
