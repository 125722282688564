.center {
  text-align: center;
}
.right {
  text-align: right;
}
.block {
  display: block;
  width: 100%;
}
.block-up-to-xs {
  @media (max-width: @bp-xs-max) {
    .block();
  }
}
.nowrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.overflow-auto {
  max-width: 100%;
  padding-bottom: 10px;
  overflow-x: auto;
}

.overflow-hidden {
  overflow: hidden;
}
